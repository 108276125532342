import React from 'react';
import { graphql } from 'gatsby';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import { BlogPostCollection, Layout, SEO } from '../components';

const BlogHome = ({ data }) => {
  const posts = data.posts.nodes;
  return (
    <Layout>
      <SEO title="Recent blog posts" />
      <Container>
        <Box my={4}>
          <Typography variant="overline" component="h1" gutterBottom>
            The Blog
          </Typography>

          <BlogPostCollection posts={posts} />
        </Box>
      </Container>
    </Layout>
  );
};

export default BlogHome;

export const query = graphql`
  {
    posts: allMdx(
      filter: { frontmatter: { type: { eq: "blog" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        id
        excerpt(pruneLength: 300)
        fileAbsolutePath
        frontmatter {
          author
          heroImage {
            author {
              name
              url
            }
            url
          }
          date
          subtitle
          tags
          title
        }
      }
    }
  }
`;
